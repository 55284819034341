/* src/App.css */
.login-container, .dashboard-container {
  width: 300px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-container h2, .dashboard-container h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.login-container form div {
  margin-bottom: 1rem;
}

.login-container label {
  display: block;
  margin-bottom: 0.5rem;
}

.login-container input {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}

.login-container button {
  width: 100%;
  padding: 0.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.login-container button:disabled {
  background-color: #007bff;
  cursor: not-allowed;
}

.error {
  color: red;
  text-align: center;
  margin-top: 1rem;
}

.ql-toolbar{
  background-color: #F3F4F6;
}

.ql-editor {
  background-color: #E5E7EB;
}
